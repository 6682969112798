define([
    'lodash',
    'react',
    'coreUtils',
    'prop-types',
    'santa-components',
    'wixFreemiumBanner/utils/translationUtils',
    'wixFreemiumBanner/css/wixAdsResponsiveCss'
], function (_, React, coreUtils, PropTypes, santaComponents, translationsUtils, wixAdsCss) {
    'use strict';
    const createReactElement = santaComponents.utils.createReactElement;

    function getBannerProps(isPreview, href, wixAdsId, overlay) {
        const classes = ['desktop-top'];

        const props = {
            id: 'top',
            key: 'desktopTop',
            'data-aid': `${wixAdsId}desktopTop`
        };

        if (isPreview) {
            classes.push('preview');
        }

        if (overlay) {
            classes.push('overlay');
        } else {
            _.assign(props, {
                href,
                'target': '_blank',
                'rel': 'nofollow'
            });
        }

        props.className = classes.join(' ');

        return props;
    }

    function getWixAdsElement(props) {
        const {wixAdsId, isPreview, metaSiteId, translations, overlay} = props;

        if (overlay) {
            return createReactElement('div', 
                getBannerProps(isPreview, null, wixAdsId, overlay));
        }

        const adsTranslation = translationsUtils.getWixFreemiumTranslations(translations, isPreview, metaSiteId);
        const bannerUrl = isPreview ? `${adsTranslation.responsiveBannerUrlPreview}&orig_msid=${metaSiteId}` : adsTranslation.responsiveBannerUrl;
        const text = isPreview ? adsTranslation.responsiveBannerTextPreview : adsTranslation.responsiveBannerText;
        return createReactElement('a', getBannerProps(isPreview, bannerUrl, wixAdsId),
            createReactElement('span', {className: 'contents'}, [
                createReactElement('span', {key: 'responsiveBannerText', 'className': 'text'}, text),
                isPreview ? createReactElement('span', {key: 'responsiveBannerButton', 'className': 'button'}, adsTranslation.desktopBanner2Button) : null
            ]));
    }

    class BannerResponsive extends React.Component {
        render() {
            const {wixAdsId, isWixAdsAllowed, direction} = this.props;

            const wrapperClasses = {
                'wix-ads-responsive': true,
                visible: isWixAdsAllowed,
                hidden: !isWixAdsAllowed
            };
            wrapperClasses[direction] = true;

            const wrapperProps = {
                ref: 'wrapper',
                id: wixAdsId,
                className: coreUtils.classNames(wrapperClasses)
            };

            const wrapperChildren = [
                santaComponents.utils.styleNodeUtils.generateStyleNode('wixAds-style', wixAdsCss)
            ];

            wrapperChildren.push(getWixAdsElement(this.props));

            return createReactElement('div', wrapperProps, wrapperChildren);
        }
    }
    BannerResponsive.displayName = 'WixFreemiumBannerResponsive';
    BannerResponsive.propTypes = {
        wixAdsId: PropTypes.string,
        isPreview: PropTypes.bool,
        isWixAdsAllowed: PropTypes.bool,
        metaSiteId: PropTypes.string,
        translations: PropTypes.object,
        direction: PropTypes.string,
        overlay: PropTypes.bool
    };
    return BannerResponsive;
});
